/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
altai-content-item-inline-security .form-group {
  margin-bottom: 20px;
  display: block;
  padding: 10px 0;
  background-color: #f1f1f1;
}
